<template>
  <div>
    <a-card :title="$t('出库订单')">
      <a-button slot="extra" type="primary" ghost @click="$router.go(-1)"> <a-icon type="left" />{{ $t("返回") }}</a-button>

      <a-spin :spinning="loading">
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 12 }" :wrapper-col="{ span: 12 }">
          <a-row>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="external_number" :label="$t('订单号')">
                <a-input v-model="form.external_number" allowClear />
              </a-form-model-item>
            </a-col>
            <!-- <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="warehouse" :label="$t('仓库')">
                <a-select v-model="form.warehouse" style="width: 100%">
                  <a-select-option v-for="item in warehouseItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="order_type" :label="$t('订单类型')">
                <a-select v-model="form.order_type" style="width: 100%">
                  <a-select-option v-for="item in orderTypeItems" :key="item.id" :value="item.id">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col> -->
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="customer_contact" :label="$t('联系人')">
                <a-input v-model="form.customer_contact" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="province" :label="$t('省')">
                <a-select v-model="form.province" allowClear style="width: 100%">
                  <a-select-option v-for="item in provinceItems" :key="item.code" :value="item.code">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="city" :label="$t('市')">
                <a-select v-model="form.city" allowClear style="width: 100%">
                  <a-select-option v-for="item in filteredCityItems" :key="item.code" :value="item.code">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="area" :label="$t('区')">
                <a-select v-model="form.area" allowClear style="width: 100%">
                  <a-select-option v-for="item in filteredAreaItems" :key="item.code" :value="item.code">
                    {{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="detail_address" :label="$t('详细地址')">
                <a-input v-model="form.detail_address" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="contact_phone" :label="$t('联系人电话')">
                <a-input v-model="form.contact_phone" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="delivery_date" :label="$t('预计出库日')">
                <a-date-picker v-model="form.delivery_date" valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 100%" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="arrival_date" :label="$t('预计到货日')">
                <a-date-picker v-model="form.arrival_date" valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 100%" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="delivery_method" :label="$t('发货方式')">
                <a-input v-model="form.delivery_method" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="payment_method" :label="$t('付款方式')">
                <a-input v-model="form.payment_method" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="express" :label="$t('快递')">
                <a-input v-model="form.express" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="packaging_requirements" :label="$t('包装要求')">
                <a-input v-model="form.packaging_requirements" allowClear />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="delivery_time" :label="$t('送货时间')">
                <a-date-picker v-model="form.delivery_time" valueFormat="YYYY-MM-DD HH:mm:ss" style="width: 100%" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" style="width: 380px">
              <a-form-model-item prop="remark" :label="$t('备注')">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider></a-divider>

        <div>
          <a-row gutter="16">
            <a-col :span="24" :md="8" :xl="6" style="width: 256px">
              <a-input
                v-model="materialNumber"
                :placeholder="$t('请扫描产品条码')"
                @keyup.enter.native="scanMaterial"
                allowClear
              />
            </a-col>
            <a-space>
              <a-button type="primary" @click="openInventoryModal">{{ $t("选择产品") }}</a-button>
            </a-space>
          </a-row>

          <div style="margin-top: 16px">
            <a-table
              rowKey="id"
              size="small"
              :loading="tableLoading"
              :columns="columns"
              :data-source="deliveryMaterialItems"
              :pagination="false"
            >
              <div slot="batch" slot-scope="value, item">
                <batch-search-select
                  v-model="item.batch"
                  :placeholder="$t('批次')"
                  :warehouse="form.warehouse"
                  :material="item.material"
                  size="small"
                  :defaultItem="item"
                >
                </batch-search-select>
              </div>
              <div slot="total_quantity" slot-scope="value, item">
                <a-input-number v-model="item.total_quantity" size="small"></a-input-number>
              </div>
              <div slot="weight" slot-scope="value, item">
                <span v-if="item.total_quantity && item.material_weight">
                  {{ NP.times(item.total_quantity, item.material_weight) }}
                </span>
              </div>
              <div slot="volume" slot-scope="value, item">
                <span v-if="item.total_quantity && item.material_volume">
                  {{ NP.times(item.total_quantity, item.material_volume) }}
                </span>
              </div>
              <div slot="action" slot-scope="value, item, index">
                <a-button-group size="small">
                  <a-button type="danger" @click="removeMaterial(item, index)">{{ $t("删除") }}</a-button>
                  <a-button type="primary" @click="copyMaterial(item, index)">{{ $t("复制") }}</a-button>
                  <a-button @click="autoPicking(item, index)">{{ $t("自动拣配") }}</a-button>
                </a-button-group>
              </div>
            </a-table>
          </div>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm :title="$t('确定创建吗?')" @confirm="create">
          <a-button type="primary" :loading="loading">{{ $t("创建") }}</a-button>
        </a-popconfirm>
      </div>
    </a-card>

    <inventory-select-modal
      v-model="materialModalVisible"
      :warehouse="form.warehouse"
      :client="form.client"
      @select="onSelectMaterial"
    >
    </inventory-select-modal>
  </div>
</template>

<script>
import { orderTypeOption, warehouseOption, materialOption } from "@/api/option";
import { stockOutOrderCreate } from "@/api/order";
import moment from "moment";
import NP from "number-precision";
import { batchOption } from "@/api/option";

export default {
  components: {
    InventorySelectModal: () => import("@/components/InventorySelectModal/index"),
    BatchSearchSelect: () => import("@/components/BatchSearchSelect/index"),
  },
  data() {
    return {
      NP,
      form: {
        external_number: `CK${Number(new Date())}`,
      },
      rules: {
        external_number: [
          { required: true, message: this.$t("请输入订单号"), trigger: "change" },
          { max: 32, message: this.$t("超出最大长度 (32)"), trigger: "change" },
        ],
        receiving_address: [{ max: 256, message: this.$t("超出最大长度 (256)"), trigger: "change" }],
        remark: [{ max: 256, message: this.$t("超出最大长度 (256)"), trigger: "change" }],
      },
      moment,
      columns: [
        {
          title: this.$t("序号"),
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: this.$t("产品编号"),
          dataIndex: "material_number",
          key: "material_number",
        },
        {
          title: this.$t("产品名称"),
          dataIndex: "material_name",
          key: "material_name",
        },
        {
          title: this.$t("批次"),
          key: "batch",
          scopedSlots: { customRender: "batch" },
          width: 240,
        },
        {
          title: this.$t("数量"),
          key: "total_quantity",
          scopedSlots: { customRender: "total_quantity" },
        },
        {
          title: this.$t("单位"),
          dataIndex: "unit",
          key: "unit",
        },
        {
          title: this.$t("总体积"),
          dataIndex: "volume",
          key: "volume",
          scopedSlots: { customRender: "volume" },
        },
        {
          title: this.$t("规格"),
          dataIndex: "material_spec",
          key: "material_spec",
        },
        {
          title: this.$t("操作"),
          dataIndex: "action",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      loading: false,
      tableLoading: false,

      deliveryMaterialItems: [],
      orderTypeItems: [],

      userItems: [],
      clientItems: [],
      warehouseItems: [],
      materialNumber: "",
      materialModalVisible: false,

      provinceItems: [],
      cityItems: [],
      areaItems: [],
    };
  },
  computed: {
    filteredCityItems() {
      const items = [];
      if (this.form.province) {
        for (const item of this.cityItems) {
          if (this.form.province == item.provinceCode) {
            items.push(item);
          }
        }
      }
      return items;
    },
    filteredAreaItems() {
      const items = [];
      if (this.form.city) {
        for (const item of this.areaItems) {
          if (this.form.city == item.cityCode) {
            items.push(item);
          }
        }
      }
      return items;
    },
  },
  methods: {
    onSelectMaterial(item) {
      let index = this.deliveryMaterialItems.findIndex((_item) => _item.id == item.id);
      if (index != -1) {
        this.$message.warn(this.$t("产品已存在"));
        return;
      }

      this.deliveryMaterialItems = this.$functions.insertItem(this.deliveryMaterialItems, {
        id: item.material,
        material: item.material,
        material_name: item.material_name,
        material_number: item.material_number,
        total_quantity: 1,
        unit: item.unit,
        material_volume: item.material_volume,
        material_weight: item.material_weight,
        material_spec: item.material_spec,
      });
    },
    create() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          if (this.deliveryMaterialItems.length == 0) {
            this.$message.warn(this.$t("未添加产品"));
            return;
          }

          for (let item of this.deliveryMaterialItems) {
            if (!item.batch) {
              this.$message.warn(this.$t("未选择批次"));
              return;
            }
          }

          let provinceIndex = this.provinceItems.findIndex((item) => item.code == this.form.province);
          let cityIndex = this.cityItems.findIndex((item) => item.code == this.form.city);
          let areaIndex = this.areaItems.findIndex((item) => item.code == this.form.area);
          let province = provinceIndex === -1 ? "" : this.provinceItems[provinceIndex].name;
          let city = cityIndex === -1 ? "" : this.cityItems[cityIndex].name;
          let area = areaIndex === -1 ? "" : this.areaItems[areaIndex].name;
          let address = this.form.detail_address || "";
          let receivingAddress = `${province} ${city} ${area} ${address}`;

          let formData = {
            ...this.form,
            stock_out_material_items: this.deliveryMaterialItems,
            receiving_address: receivingAddress,
          };
          this.loading = true;
          stockOutOrderCreate(formData)
            .then((data) => {
              this.$message.success(this.$t("创建成功"));
              this.$router.push({ path: "/order/stock_out_order_list" });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    scanMaterial() {
      if (!this.materialNumber) {
        this.$message.warning(this.$t("请扫描产品条码"));
        return false;
      }
      this.tableLoading = true;
      materialOption({ number: this.materialNumber })
        .then((data) => {
          if (!data.results.length) {
            this.$message.warning(this.$t("没有找到相关产品，请确认扫描的产品编号！"));
            return false;
          }
          let item = data.results[0];
          let index = this.deliveryMaterialItems.findIndex((_item) => _item.id == item.id);
          if (index != -1) {
            this.deliveryMaterialItems = this.$functions.replaceItem(this.deliveryMaterialItems, {
              id: item.id,
              material: item.id,
              material_name: item.name,
              material_number: item.number,
              total_quantity: this.deliveryMaterialItems[index].total_quantity + 1,
              unit: item.unit,
            });
          } else {
            this.deliveryMaterialItems = this.$functions.insertItem(this.deliveryMaterialItems, {
              id: item.id,
              material: item.id,
              material_name: item.name,
              material_number: item.number,
              total_quantity: 1,
              unit: item.unit,
            });
          }
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
    openInventoryModal() {
      this.materialModalVisible = true;
    },
    removeMaterial(item, index) {
      this.deliveryMaterialItems.splice(index, 1);
    },
    copyMaterial(item, index) {
      this.deliveryMaterialItems.splice(index, 0, { ...item });
    },
    autoPicking(item, index) {
      this.tableLoading = true;
      let searchForm = {
        page: 1,
        page_size: 999999,
        has_stock: true,
        warehouse: this.form.warehouse,
        material: item.material,
      };
      batchOption(searchForm)
        .then((data) => {
          let materialItems = [];
          let totalQuantity = item.total_quantity;
          for (let batchItem of data.results) {
            if (totalQuantity > batchItem.remain_quantity) {
              materialItems.push({
                ...item,
                batch: batchItem.id,
                batch_number: batchItem.number,
                total_quantity: batchItem.remain_quantity,
              });

              totalQuantity -= batchItem.remain_quantity;
            } else {
              materialItems.push({
                ...item,
                batch: batchItem.id,
                batch_number: batchItem.number,
                total_quantity: totalQuantity,
              });

              break;
            }
          }
          this.deliveryMaterialItems.splice(index, 1, ...materialItems);
        })
        .finally(() => {
          this.tableLoading = false;
        });
    },
  },
  mounted() {
    this.provinceItems = require("@/utils/provinces.json");
    this.cityItems = require("@/utils/cities.json");
    this.areaItems = require("@/utils/areas.json");
  },
};
</script>
